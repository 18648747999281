// export type Select2Data<T> = unknown & { id: string; text: string };

// Setup Select2
//
export async function setupSelect2(
    source: object[] | string,
    selectName: string,
    placeholder: string,
    parent: string,
    dataTextField: string,
    objectIdField: string = "id",
    isMultiple: boolean = false,
    allowClear: boolean = false
) {
    const data = (source instanceof Array ? [...source] : await $.ajax({ url: source })) as any[]; // make a copy if it's array or search the data from the URL

    // prepare the data
    const results = data.map((item: any) => {
        const selectItem = { ...item };

        // TODO fix this any type .. maybe with Record or something else
        if (objectIdField != "id") {
            selectItem.id = item[objectIdField];
        }
        selectItem.text = item[dataTextField];

        return selectItem;
    });

    results.sort((a: { text: string }, b: { text: string }) => a.text.localeCompare(b.text)); // changed during the migration to TS

    // load the select2 object
    $(`${parent} ${selectName}`).select2({
        data: results,
        placeholder: placeholder,
        theme: "bootstrap",
        dropdownParent: $(parent),
        multiple: isMultiple,
        allowClear: allowClear,
    });
}
